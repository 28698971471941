
import { useStore } from "vuex";
import { defineComponent, ref, onMounted, computed } from "vue";
import moment from "moment";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
interface RiderDetailModel {
  users: RiderInfo;
  users_info: RiderVahicle;
  users_info_image: RiderDocument;
}

interface RiderInfo {
  id: number;
  users_id: string;
  users_name: string;
  users_first_name: string;
  users_last_name: string;
  users_email: string;
  users_phone: string;
  users_create_by: string;
  users_ubdate_by: string;
  created_at: string;
  update_at: string;
  user_pdpa_status: number;
  users_original_tel: string;
  password: string;
  user_status: number;
}

interface RiderVahicle {
  id: number;
  ri_user_code: string;
  ri_vehicle_brand: string;
  ri_vehicle_model: number;
  ri_vehicle_model_name: string;
  ri_vehicle_model_year: string;
  ri_plate_number: string;
  ri_order_status: boolean;
  ri_motor_box: boolean;
  ri_drivg_licent_no: string;
  ri_id_card_no: string;
  ri_registerbook: string;
  ri_district_id: string;
  ri_bookbank_no: string;
  ri_bookbank_name: string;
  ri_bookbank_brand: number;
  ri_users_id: number;
  ri_provinces_id: number;
}

interface RiderDocument {
  ri_driving_license_front_img: string;
  ri_plate_number_img: string;
  ri_id_card_img: string;
  ri_registerbook_img: string;
  ri_profile_img: string;
  ri_book_bank_img: string;
  ri_phorabo_img: string;
}

export default defineComponent({
  name: "BillManagementDetail",
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const route = useRoute();
    const router = useRouter();

    const check = ref(false);
    const slidepage = ref(true);
    const slidepaynow = ref(false);
    const isLoadingPage = ref(true);
    const data = ref({});
    const isLoadingData = ref(true);

    async function Popuppage() {
      console.log(setbillpaycheck.value);
      check.value = true;
    }

    async function Done() {
      await store.commit("BillModule/" + Mutations.BILL_PAY_CHECK, {
        bill_complete: [],
        bill_fail: [],
      });
      slidepage.value = true;
      check.value = false;
      router.replace({ path: "/management/bill-management" });
    }

    async function toPay(setbillid) {
      console.log(setbillpaycheck.value);
      isLoadingPage.value = true;
      let payload = [setbillid];
      await store.dispatch(`BillModule/${Actions.TOPUP_CREDIT_PAY}`, {
        transactionPay: payload,
      });
      isLoadingPage.value = false;
      slidepaynow.value = true;
    }

    const riderDetail = ref({
      users_info_image: {},
      users: {},
      users_info: {},
    } as RiderDetailModel);

    async function init() {
      isLoadingData.value = true;
      await store.dispatch("BillModule/" + Actions.FETCH_BILL_BY_ID, {
        transactionId: route.params.transactionId,
      });
      return;
    }

    onMounted(async () => {
      if (setbillid.value.bill_id == 0) {
        await init();
      }
      isLoadingData.value = false;
    });

    const setbillid = computed(() => {
      return store.state.BillModule.setbillid; //เข้าไปที่ state.ตัวแปรข้อมูล
    });

    const setbillidcheck = computed(() => {
      return store.state.BillModule.setbillpaycheck; //เข้าไปที่ state.ตัวแปรข้อมูล
    });

    const setbillpaycheck = computed(() => {
      return store.state.BillModule.setbillpaycheck;
    });

    return {
      moment,
      isLoading,
      riderDetail,
      router,
      Popuppage, //ฟังค์ชัน event เมื่อ click
      check,
      slidepage,
      slidepaynow,
      Done,
      isLoadingPage,
      //setTime,
      data,
      setbillid,
      toPay,
      setbillidcheck,
      setbillpaycheck,
      isLoadingData,
    };
  },
});
